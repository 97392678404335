import React, { lazy } from "react";
import {
  createHashRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import { HOME_PATH } from "./constants";

const App = lazy(() => import("./App"));
const Home = lazy(() => import("./Home"));
const SignUp = lazy(() => import("./SignUp/SignUp"));
const One = lazy(() => import("./One"));
const TermsAndPrivacy = lazy(() => import("./TermsAndPrivacy"));
const NotFound = lazy(() => import("./NotFound"));

const router = createHashRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index element={<Home />} />
      <Route path={HOME_PATH} element={<Home />} />

      <Route path="signup" element={<SignUp />} />
      <Route path="forgot" element={<Home />} />
      <Route path="one" element={<One />} />

      <Route path="privacy" element={<TermsAndPrivacy />} />
      <Route path="terms" element={<TermsAndPrivacy />} />

      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

export default router;
